/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import template from "templates/course_map/course_map_layout.hbs";

import { getOption } from "backbone.marionette";

import CollectionProxy from "collections/collection_proxy";
import CourseMapFilters from "views/course_map/filters";
import CourseMapProjects from "views/course_map/projects";
import CourseMapCreators from "views/course_map/creators";
import CourseMapMatrix from "views/course_map/matrix";
import WarningView from "views/course_map/warning";
import AbstractMatrix from "views/abstract/abstract_matrix";
import ModalErrorView from "views/modal/modal_error";

export default class CourseMapLayout extends AbstractMatrix.extend({
  children: {},
  minWidth: 175,
  capturedScroll: 0,
  stickyHeader: true,
  template,
  creatorType: "User",
  filtersView: null,
  projectsView: null,
  creatorsView: null,
  matrixView: null,

  ui: {
    detail: '[data-region="detail"]',
    sliderWrapper: '[data-behavior="matrix-slider"]',
    sliderLeft: '[data-behavior="matrix-slider-left"]',
    sliderRight: '[data-behavior="matrix-slider-right"]',
    hideOnWarning: '[data-behavior="hide-on-warning"]'
  },

  triggers: {
    'click [data-behavior="matrix-slider-left"]': "slider:left",
    'click [data-behavior="matrix-slider-right"]': "slider:right"
  },

  regions: {
    filters: '[data-region="filters"]',
    creators: '[data-region="creators"]',
    projects: '[data-region="projects"]',
    matrix: '[data-region="matrix"]',
    globalFlash: '[data-region="flash"]',
    warning: '[data-region="warning"]'
  }
}) {
  setupListeners() {
    // Set up debounce scroll event
    const debouncedScroll = _.debounce(_.bind(this.onScroll, this), 500);
    this.getUI("sliderWrapper").on("scroll", debouncedScroll);

    this.unloadListener = window.addEventListener('beforeunload', (event) => {
      this.unloading = true;
    });
    this.listenTo(this, "redraw", function() {
      return this.adjustToCurrentPosition();
    });
    this.listenTo(this, "navigate:submission", function(args) {
      return this.navigateToSubmission(args.project, args.creator);
    });
    this.listenTo(this, "navigate:creator", function(args) {
      return this.navigateToCreator(args.creator);
    });
    this.listenTo(this, "navigate:project", function(args) {
      return this.navigateToProject(args.project);
    });
    return this.listenTo(this, "filter:projects", function(args) {
      return this.filterProjects(args);
    });
  }

  onDestory() {
    if (this.unloadListener) {
      window.removeEventListener(this.unloadListener);
    }
  }

  filterProjects({ name }) {
    this.projectCollection = this.typeProxiedProjectCollection({ name });
    this.createChildViews();
    this.showChildView("creators", this.creatorsView);
    this.showChildView("projects", this.projectsView);
    this.showChildView("matrix", this.matrixView);
    this.sliderPosition = 0;
    this.parentOnShow();
    this.onScroll();
  }

  navigateToProject(projectId) {
    if (this.creatorType === "User") {
      return window.Vocat.router.navigate(
        `courses/${this.courseId}/users/evaluations/project/${projectId}`,
        true
      );
    }
    if (this.creatorType === "Group") {
      return window.Vocat.router.navigate(
        `courses/${this.courseId}/groups/evaluations/project/${projectId}`,
        true
      );
    }
  }

  navigateToSubmission(projectId, creatorId) {
    const typeSegment = `${this.creatorType.toLowerCase()}s`;
    const url = `courses/${this.courseId}/${typeSegment}/evaluations/creator/${creatorId}/project/${projectId}`;
    return window.Vocat.router.navigate(url, true);
  }

  navigateToCreator(creatorId) {
    if (this.creatorType === "User") {
      return window.Vocat.router.navigate(
        `courses/${this.courseId}/users/evaluations/creator/${creatorId}`,
        true
      );
    }
    if (this.creatorType === "Group") {
      return window.Vocat.router.navigate(
        `courses/${this.courseId}/groups/evaluations/creator/${creatorId}`,
        true
      );
    }
  }

  typeProxiedProjectCollection(filter = {}) {
    const projectType = `${this.creatorType}Project`;
    let projects = [];

    if (filter.name) {
      projects = this.collections.project.byName(filter.name);
    } else {
      projects = this.collections.project;
    }

    const proxiedCollection = CollectionProxy(projects);
    proxiedCollection.where(function(model) {
      const abilities = model.get("abilities");
      return (
        abilities.can_show_submissions === true &&
        (model.get("type") === projectType ||
          model.get("type") === "OpenProject")
      );
    });
    return proxiedCollection;
  }

  typeProxiedCreatorCollection() {
    if (this.creatorType === "User") {
      return this.collections.user;
    }
    if (this.creatorType === "Group") {
      return this.collections.group;
    }
    return new Backbone.Collection();
  }

  showChildViews() {
    this.showChildView("filters", this.filtersView);
    this.showChildView("creators", this.creatorsView);
    this.showChildView("projects", this.projectsView);
    this.showChildView("matrix", this.matrixView);
    this.sliderPosition = 0;
    this.parentOnShow();
    return this.bindUIElements();
  }

  onRender() {
    if (this.projectCollection.length === 0) {
      return this.showEmptyWarning(this.creatorType, "Project");
    }
    if (this.creatorCollection.length === 0) {
      return this.showEmptyWarning(this.creatorType, "Creator");
    }
    this.createChildViews();
    this.setupListeners();
    this.showChildViews();

    // Load first 3 visible projects
    setTimeout(() => {
      const projectIds = this.getVisibleProjects();
      this._loadProjects(projectIds);
    }, 0);

    return true;
  }

  createChildViews() {
    this.filtersView = new CourseMapFilters({ vent: this });
    this.creatorsView = new CourseMapCreators({
      collection: this.creatorCollection,
      courseId: this.courseId,
      vent: this,
      creatorType: this.creatorType
    });
    this.projectsView = new CourseMapProjects({
      collection: this.projectCollection,
      courseId: this.courseId,
      vent: this
    });
    return (this.matrixView = new CourseMapMatrix({
      collection: this.creatorCollection,
      collections: {
        project: this.projectCollection,
        submission: this.collections.submission
      },
      courseId: this.courseId,
      creatorType: this.creatorType,
      vent: this
    }));
  }

  initialize(options) {
    this.collections = getOption(this, "collections");
    this.courseId = getOption(this, "courseId");
    this.creatorType = getOption(this, "creatorType");
    this.projectCollection = this.typeProxiedProjectCollection();
    this.creatorCollection = this.typeProxiedCreatorCollection();
    this.loadedProjectIds = [];
    this.unloading = false;
    this.unloadListener = null;
    return this.collections.submission;
  }

  showEmptyWarning(creatorType, warningType) {
    this.showChildView(
      "warning",
      new WarningView({ creatorType, warningType, courseId: this.courseId })
    );
    return this.getUI("hideOnWarning").hide();
  }

  onScroll() {
    const projectIds = this.getVisibleProjects();
    this.onMatrixScroll();
    this._loadProjects(projectIds);
  }

  onEvaluationsPublish(project) {
    const endpoint = `${project.url()}/publish_evaluations`;
    return $.ajax(endpoint, {
      type: "put",
      dataType: "json",
      data: {},
      success: (data, textStatus, jqXHR) => {
        Radio.channel("app").trigger("error:add", {
          level: "notice",
          lifetime: 4000,
          msg: `Your evaluations for ${project.get(
            "name"
          )} submissions have been published`
        });
        const submissions = this.collections.submission.where({
          project_id: project.id
        });
        return submissions.forEach(submission =>
          submission.set("current_user_published", true)
        );
      },
      error: (jqXHR, textStatus, error) => {
        return Radio.channel("app").trigger("error:add", {
          level: "notice",
          lifetime: 4000,
          msg: "Unable to publish submissions."
        });
      }
    });
  }

  onEvaluationsUnpublish(project) {
    const endpoint = `${project.url()}/unpublish_evaluations`;
    return $.ajax(endpoint, {
      type: "put",
      dataType: "json",
      data: {},
      success: (data, textStatus, jqXHR) => {
        Radio.channel("app").trigger("error:add", {
          level: "notice",
          lifetime: 4000,
          msg: `Your evaluations for ${project.get(
            "name"
          )} submissions have been unpublished`
        });
        const submissions = this.collections.submission.where({
          project_id: project.id
        });
        return submissions.forEach(submission =>
          submission.set("current_user_published", false)
        );
      },
      error: (jqXHR, textStatus, error) => {
        return Radio.channel("app").trigger("error:add", {
          level: "notice",
          lifetime: 4000,
          msg: "Unable to unpublish submissions."
        });
      }
    });
  }

  serializeData() {
    const out = {
      creatorType: this.creatorType,
      hasGroupProjects: this.collections.project.hasGroupProjects(),
      hasUserProjects: this.collections.project.hasUserProjects()
    };
    return out;
  }

  _loadProjects(projectIds) {
    const courseId = this.courseId;

    const filteredIds = projectIds
      .map(id => parseInt(id, 10))
      .filter(id => !this.loadedProjectIds.includes(id));

    if (filteredIds && filteredIds.length > 0) {
      this.collections.submission.fetch({
        // We want the models returned to be added to the current collection.
        // Do not remove models that are not returned by this call.
        remove: false,
        data: { course: courseId, projects: filteredIds },
        success: () => {
          this.loadedProjectIds = [...this.loadedProjectIds, ...filteredIds];
        },
        error: (error) => {
          // If the user is navigating to another page, do not show error modal.
          if (this.unloading) return;

          return Radio.channel("app").trigger(
            "modal:open",
            new ModalErrorView({
              model: this.model,
              vent: this,
              message:
                "Exception: Unable to fetch collection models. Please report this error to your VOCAT administrator."
            })
          );
        }
      });
    }
  }
}
