/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import VocatController from "controllers/vocat_controller";
import UserCollection from "collections/user_collection";
import ProjectCollection from "collections/project_collection";
import SubmissionForCourseCollection from "collections/submission_for_course_collection";
import GroupSubmissionCollection from "collections/submission_for_group_collection";
import CourseUserSubmissionCollection from "collections/submission_for_course_user_collection";
import GroupCollection from "collections/group_collection";
import AssetCollection from "collections/asset_collection";
import AssetDetail from "views/assets/asset_detail";
import CourseMap from "views/course_map/course_map";
import SubmissionDetail from "views/submission/submission_layout";
import CreatorDetail from "views/course_map/detail_creator";
import ProjectDetail from "views/project/detail";
import ApplicationErrorView from "views/error/application_error";
import AssetModel from "models/asset";
import ModalErrorView from "views/modal/modal_error";

export default class CourseMapController extends VocatController.extend({
  collections: {
    user: new UserCollection([], {}),
    group: new GroupCollection([], {}),
    project: new ProjectCollection([], {}),
    submission: new SubmissionForCourseCollection([], {}),
    asset: new AssetCollection([], {})
  },

  layoutInitialized: false,
  submissionsSynced: false
}) {
  initialize() {
    this._loadSubmissions = _.throttle(
      _.bind(this._doLoadSubmissions, this),
      5000
    );
    this.unloading = false;
    this.unloadListener = null;
    return this.bootstrapCollections();
  }

  setupListeners() {
    this.unloadListener = window.addEventListener('beforeunload', (event) => {
      this.unloading = true;
    });
  }

  onDestory() {
    if (this.unloadListener) {
      window.removeEventListener(this.unloadListener);
    }
  }

  userCourseMap(courseId) {
    // Get the first 3 project ids
    const projectIds = this._getInitProjectIds(["OpenProject", "UserProject"]);

    // Load just these projects on init
    this._loadSubmissions(courseId, projectIds);
    const courseMap = new CourseMap({
      creatorType: "User",
      courseId,
      collections: this.collections
    });

    return window.Vocat.getRegion().show(courseMap);
  }

  groupCourseMap(courseId) {
    // Get the first 3 project ids
    const projectIds = this._getInitProjectIds(["OpenProject", "GroupProject"]);

    this._loadSubmissions(courseId, projectIds);
    const courseMap = new CourseMap({
      creatorType: "Group",
      courseId,
      collections: this.collections
    });
    return window.Vocat.getRegion().show(courseMap);
  }

  groupProjectDetail(courseId, projectId) {
    return this._showProjectDetail(projectId, "Group");
  }

  userProjectDetail(courseId, projectId) {
    return this._showProjectDetail(projectId, "User");
  }

  groupSubmissionDetail(courseId, creatorId, projectId) {
    return this._showSubmissionDetail("Group", courseId, creatorId, projectId);
  }

  groupSubmissionDetailAsset(courseId, creatorId, projectId, assetId) {
    return this._showSubmissionDetail(
      "Group",
      courseId,
      creatorId,
      projectId,
      assetId
    );
  }

  userSubmissionDetail(courseId, creatorId, projectId) {
    return this._showSubmissionDetail("User", courseId, creatorId, projectId);
  }

  userSubmissionDetailAsset(courseId, creatorId, projectId, assetId) {
    return this._showSubmissionDetail(
      "User",
      courseId,
      creatorId,
      projectId,
      assetId
    );
  }

  userCreatorDetail(courseId, creatorId) {
    return this._showCreatorDetail("User", courseId, creatorId);
  }

  groupCreatorDetail(courseId, creatorId) {
    return this._showCreatorDetail("Group", courseId, creatorId);
  }

  standaloneUserProjectDetail(courseId, projectId) {}
  // TODO: Move standalone details into this controller

  assetDetail(courseId, assetId) {
    return this._loadAsset(assetId).done(asset => {
      const assetDetail = new AssetDetail({
        courseId,
        model: asset,
        context: "coursemap"
      });
      return window.Vocat.getRegion().show(assetDetail);
    });
  }

  getNextCreator(creatorType, creatorId) {
    if (creatorType === "User") {
      const creatorIndex = this.collections.user.models.findIndex(
        m => m.attributes.id === creatorId
      );
      const nextIndex = creatorIndex + 1;
      if (nextIndex <= this.collections.user.models.length)
        return this.collections.user.models[nextIndex];
      return null;
    }
    const creatorIndex = this.collections.group.models.findIndex(
      m => m.attributes.id === creatorId
    );
    const nextIndex = creatorIndex + 1;
    if (nextIndex <= this.collections.group.models.length)
      return this.collections.group.models[nextIndex];
    return null;
  }

  getPrevCreator(creatorType, creatorId) {
    if (creatorType === "User") {
      const creatorIndex = this.collections.user.models.findIndex(
        m => m.attributes.id === creatorId
      );
      const prevIndex = creatorIndex - 1;
      if (prevIndex >= 0) return this.collections.user.models[prevIndex];
      return null;
    }
    const creatorIndex = this.collections.group.models.findIndex(
      m => m.attributes.id === creatorId
    );
    const prevIndex = creatorIndex - 1;
    if (prevIndex >= 0) return this.collections.group.models[prevIndex];
    return null;
  }

  /** Returns the first 3 project ids */
  _getInitProjectIds(
    types = ["OpenProject", "GroupProject", "UserProject"],
    count = 3
  ) {
    return this.collections.project.models
      .filter(project => types.includes(project.attributes.type))
      .map(project => project.id)
      .slice(0, count);
  }

  _loadAsset(assetId) {
    const deferred = $.Deferred();
    const asset = new AssetModel({ id: assetId });
    asset.fetch({
      success() {
        return deferred.resolve(asset);
      }
    });
    return deferred;
  }

  _doLoadSubmissions(courseId, projectIds = []) {
    return this.collections.submission.fetch({
      reset: true,
      data: { course: courseId, projects: projectIds },
      error: () => {
        // If the user is navigating to another page, do not show error modal.
        if (this.unloading) return;

        return Radio.channel("app").trigger(
          "modal:open",
          new ModalErrorView({
            model: this.model,
            vent: this,
            message:
              "Exception: Unable to fetch collection models. Please report this error to your VOCAT administrator."
          })
        );
      }
    });
  }

  _loadOneSubmission(creatorType, creatorId, projectId) {
    const deferred = $.Deferred();

    deferred.fail(this._handleSubmissionLoadError);

    // We don't deal in submission IDs in VOCAT (although I kind if wish we had), so we're fetching this
    // model outside of the usual collection/model framework. At some point, we may want to move this fetching
    // logic into a JS factory class or the submission model itself.
    $.ajax({
      url: "/api/v1/submissions/for_creator_and_project",
      method: "get",
      data: {
        creator: creatorId,
        project: projectId,
        creator_type: creatorType
      },
      success: data => {
        if (data.length > 0) {
          const raw = data.find(
            s =>
              // It's possible to get two submissions back for an open project, so we need to grab the correct one.
              parseInt(s.creator.id, 10) === parseInt(creatorId, 10) &&
              s.creator_type === creatorType
          );
          const { id } = raw;
          this.collections.submission.add(raw, { merge: true });
          const submission = this.collections.submission.get(id);
          return deferred.resolve(submission);
        }
        return deferred.reject(creatorType, creatorId, projectId, null);
      },
      error: response => {
        let status = null;
        if (response != null && (response.status != null) != null) {
          ({ status } = response);
        }
        return deferred.reject(creatorType, creatorId, projectId, status);
      }
    });
    return deferred;
  }

  _handleSubmissionLoadError(creatorType, creatorId, projectId, status) {
    let statusMsg;
    if (status) {
      statusMsg = `When Vocat tried to load this submission, the server replied with a ${status} error.`;
    } else {
      statusMsg =
        "When Vocat tried to load this submission, the server did not return a valid submission.";
    }
    return window.Vocat.getRegion().show(
      new ApplicationErrorView({
        errorDetails: {
          description: `${statusMsg} This means that the submission data is likely corrupted and needs to be repaired. \
This error has been logged and reported to the vocat development team.`,
          code: `SUBMISSION-LOAD-FAILURE: CT${creatorType}UID${creatorId}PID${projectId}`
        }
      })
    );
  }

  _showSubmissionDetail(
    creatorType,
    courseId,
    creatorId,
    projectId,
    assetId = null,
    courseMapContext
  ) {
    let modifiedCourseMapContext = courseMapContext;
    if (modifiedCourseMapContext == null) {
      modifiedCourseMapContext = true;
    }
    const deferred = this._loadOneSubmission(creatorType, creatorId, projectId);
    const nextCreator = this.getNextCreator(
      creatorType,
      parseInt(creatorId, 10)
    );
    const prevCreator = this.getPrevCreator(
      creatorType,
      parseInt(creatorId, 10)
    );
    return deferred.done(submission => {
      const submissionDetail = new SubmissionDetail({
        collections: { submission: this.collections.submission },
        courseId,
        creator: creatorId,
        project: projectId,
        model: submission,
        initialAsset: assetId,
        modifiedCourseMapContext,
        users: this.collections.user,
        groups: this.collections.group,
        nextCreator,
        prevCreator
      });
      return window.Vocat.getRegion().show(submissionDetail);
    });
  }

  _showCreatorDetail(creatorType, courseId, creatorId, courseMapContext) {
    let collection;
    let model;
    let modifiedCourseMapContext = courseMapContext;
    if (modifiedCourseMapContext == null) {
      modifiedCourseMapContext = true;
    }
    if (creatorType === "User") {
      model = this.collections.user.get(creatorId);
      collection = new CourseUserSubmissionCollection();
    } else if (creatorType === "Group") {
      model = this.collections.group.get(creatorId);
      collection = new GroupSubmissionCollection();
    }
    const nextCreator = this.getNextCreator(
      creatorType,
      parseInt(creatorId, 10)
    );
    const prevCreator = this.getPrevCreator(
      creatorType,
      parseInt(creatorId, 10)
    );
    const creatorDetail = new CreatorDetail({
      collection,
      courseId,
      model,
      modifiedCourseMapContext,
      users: this.collections.user,
      groups: this.collections.group,
      nextCreator,
      prevCreator
    });
    return window.Vocat.getRegion().show(creatorDetail);
  }

  _showProjectDetail(projectId, creatorType, courseMapContext) {
    let modifiedCourseMapContext = courseMapContext;
    if (modifiedCourseMapContext == null) {
      modifiedCourseMapContext = true;
    }
    const model = this.collections.project.get(projectId);
    const projectDetail = new ProjectDetail({
      model,
      creatorType,
      modifiedCourseMapContext
    });
    return window.Vocat.getRegion().show(projectDetail);
  }
}
